* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.music-div {
    height: 50px;
    width: 350px;
    /* border: 1px solid white; */
    display: flex;
    justify-content: end;
    align-items: center;

}

.title_and_time_container {
    height: 45px;
    width: 400px;
    /* border: 1px solid white; */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 1%; */
}

.time_container {
    height: 100%;
    width: 50%;
    /* border: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
}

.game-title {
    font-family: "Inconsolata", monospace;
    width: 100%;
    text-align: center;
}

.main-container {
    height: 100vh;
    background-color: #131314;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

.game-container {
    height: auto;
    width: 100%;
    padding: 1%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box-container {
    height: 400px;
    width: 400px;
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.first-row {
    height: auto;
    width: auto;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

.second-row {
    display: flex;
    /* border: 1px solid red; */
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}

.third-row {
    display: flex;
    /* border: 1px solid red; */
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}

.fourth-row {
    display: flex;
    /* border: 1px solid red; */
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

.fifth-row {
    display: flex;
    /* border: 1px solid red; */
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.first-row,
.second-row,
.third-row,
.fourth-row,
.fifth-row {
    gap: 8px;
}

input {
    border: none;
    outline: none;
    text-align: center;
}

.second-row .numBoxSum:nth-child(1) {
    margin-right: 5px;
}

.second-row .numBoxSum:nth-child(5) {
    margin-left: 5px;
}

.third-row .numBoxSum:nth-child(1) {
    margin-right: 5px;
}

.third-row .numBoxSum:nth-child(5) {
    margin-left: 5px;
}

.fourth-row .numBoxSum:nth-child(1) {
    margin-right: 5px;
}

.fourth-row .numBoxSum:nth-child(5) {
    margin-left: 5px;
}

.numBox {
    height: 70px;
    width: 70px;
    font-size: 30px;
    background-color: #1E1F20;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inconsolata", monospace;
    font-weight: bold;
    transition: 0.4s ease-in-out;
}

.numBoxSumGlow {
    box-shadow:
        0 0 2px 1px #4D83EF,
        0 0 2px 1px #697DE2,
        0 0 2px 1px #7D79DA,
        0 0 2px 1px #A470BE,
        0 0 2px 1px #AB6FB4,
        0 0 3px 1px #C06A93,
        0 0 3px 1px #CA6886,
        0 0 4px 1px #D96570,
        0 0 4px 1px #D76572;
    transition: 0.4s ease-in-out;
}

.keyboard-container {
    height: auto;
    width: 100%;
    max-width: 700px;
    /* border: 1px solid #ffffff; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1%;
}

.number-container {
    height: 100%;
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.numbers {
    height: 60px;
    width: 100%;
    max-width: 60px;
    min-width: 40px;
    font-family: "Lora", serif;
    font-size: 1rem;
    background-color: #292535;
    color: #ffffff;
    cursor: pointer;
    font-weight: bold;
}

.message {
    margin-top: 1%;
    color: #ffffff;
    font-family: "Inconsolata", monospace;
    margin-bottom: 1%;
}

/* CSS CODE FOR BUTTON */
.button {
    background-color: #0c0c0c;
    border: none;
    color: white;
    padding: 16px 32px;
    font-family: "Inconsolata", monospace;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    /* margin: 4px 2px; */
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;
}

.button3 {
    background-color: #f44336;
    color: rgb(255, 255, 255);
    border: 2px solid #f44336;
}

.button3:hover {
    background-color: #ff1100;
    color: white;
}

@media screen and (max-width: 768px) {
    .box-container {
        height: 350px;
        width: 350px;
        /* border: 1px solid white; */
    }

    .music-div {
        height: 50px;
        width: 250px;
        /* border: 1px solid white; */
    }

    .music-div svg {
        font-size: 20px;
    }

    .numBox {
        height: 60px;
        width: 60px;
        font-size: 20px;
    }

    .second-row .numBoxSum:nth-child(1) {
        margin-right: 2px;
    }

    .second-row .numBoxSum:nth-child(5) {
        margin-left: 2px;
    }

    .third-row .numBoxSum:nth-child(1) {
        margin-right: 2px;
    }

    .third-row .numBoxSum:nth-child(5) {
        margin-left: 2px;
    }

    .fourth-row .numBoxSum:nth-child(1) {
        margin-right: 2px;
    }

    .fourth-row .numBoxSum:nth-child(5) {
        margin-left: 2px;
    }

    .first-row {
        margin-bottom: 8px;
    }

    .fourth-row {
        margin-bottom: 8px;
    }

    .first-row,
    .second-row,
    .third-row,
    .fourth-row,
    .fifth-row {
        gap: 6px;
    }

    .keyboard-container {
        width: 90%;
        margin-top: 2%;
    }

    .title_and_time_container {
        width: 250px;
        /* border: 1px solid red; */
    }

    .message {
        font-size: 16px;
    }

    .game-title {
        font-size: 1rem;
    }

    .kb-icon svg {
        display: none;
    }

    .time_container {
        font-size: 0.9rem;
    }

    .button {
        font-size: 15px;
        padding: 10px 26px;
    }

    .numbers {
        max-width: 50px;
        min-width: 30px;
    }

    .message {
        font-size: 1rem;
    }

    .button {
        margin-top: 2%;
    }
}

@media screen and (max-width: 480px) {
    .box-container {
        height: 300px;
        width: 300px;
        /* border: 1px solid white; */
    }

    .music-div {
        height: 40px;
        width: 240px;
        /* border: 1px solid white; */
    }

    .music-div svg {
        font-size: 18px;
    }

    .numBox {
        height: 50px;
        width: 50px;
        font-size: 20px;
    }

    .game-title {
        font-size: 0.9rem;
    }

    .time_container {
        font-size: 0.8rem;
    }

    .first-row,
    .second-row,
    .third-row,
    .fourth-row,
    .fifth-row {
        gap: 5px;
    }

    .keyboard-container {
        width: 100%;
        margin-top: 3%;
    }

    .kb-icon svg {
        display: none;
    }

    .numbers {
        height: 40px;
        max-width: 40px;
        min-width: 30px;
    }

    .message {
        font-size: 12px;
    }

    .button {
        font-size: 12px;
        padding: 8px 24px;
        margin-top: 3%;
    }
}